import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [input, setInput] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const preventRightClick = (event) => {
      event.preventDefault();
    };

    const handleBeforeUnload = (event) => {
      if (isUploading) {
        event.preventDefault();
        event.returnValue = 'File submission is not complete. Are you sure you want to leave?';
      }
    };

    window.addEventListener('contextmenu', preventRightClick);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('contextmenu', preventRightClick);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUploading]);

  const handleFileChange = (files) => {
    setSelectedFiles(files);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsUploading(true);

    let ipAddress = ''
    try {
      const response = await fetch('https://api.ipify.org/?format=json');
      const data = await response.json();
      ipAddress = data.ip;
    } catch (error) {
      alert('An error occurred. Try again later.');
      setIsUploading(false);
      setInput('');
      setSelectedFiles([]);
      document.getElementById('fileInput').value = '';
      document.getElementById('action-button').textContent = 'Choose Files...';
      document.getElementById('upload-progress').value = 0;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('file', selectedFiles[i]);
      formData.append('submitterName', input);
      formData.append('ipAddress', ipAddress);
    }

    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://spring-union-7539.doublejr.workers.dev', true);

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percentComplete = (e.loaded / e.total) * 100;
        document.getElementById('upload-progress').value = percentComplete;
      }
    };

    xhr.onload = function () {
      if (this.status === 200) {
        alert('Your submission has been received.');
      } else {
        alert('Submission failed. Try again later.');
      }
      setIsUploading(false);
      setInput('');
      setSelectedFiles([]);
      document.getElementById('fileInput').value = '';
      document.getElementById('action-button').textContent = 'Choose Files...';
      document.getElementById('upload-progress').value = 0;
    };

    xhr.onerror = function () {
      alert('An error occurred. Try again later.');
      setIsUploading(false);
      setInput('');
      setSelectedFiles([]);
      document.getElementById('fileInput').value = '';
      document.getElementById('action-button').textContent = 'Choose Files...';
      document.getElementById('upload-progress').value = 0;
    }

    xhr.send(formData);
  };

  return (
    <div className="container">
      <div className="header-text">
        <p className="disney-text">Maurice Flannery</p>
        <p className="disney-subtext">A Celebration of Life</p>
        <p className="disney-subsubtext">December 8, 2024</p>
      </div>
      <div className="instructions-text">
        <p>{isUploading ? 'Please  wait...' : 'Submit your photos and videos.'}</p>
      </div>
      <div style={{ position: 'relative' }}>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', visibility: isUploading ? 'hidden' : 'visible' }}>
          <input
            id="name-field"
            type="text"
            value={input}
            placeholder="Your Name (optional)"
            onChange={(e) => setInput(e.target.value)}
          />
          <div>
            <input
              style={{ display: 'none' }}
              id="fileInput"
              type="file"
              // accept="image/*,video/*"
              onChange={(e) => handleFileChange(e.target.files)}
              multiple
            />
            <button
              id="action-button"
              type="button"
              onClick={() => {
                if (selectedFiles.length > 0) {
                  handleSubmit(new Event('submit'));
                } else {
                  document.getElementById('fileInput').click();
                }
              }}
              className="customFileUpload"
            >
              {selectedFiles.length > 0 ? `Submit ${selectedFiles.length} ${selectedFiles.length === 1 ? 'File' : 'Files'}` : 'Choose Files...'}
            </button>
          </div>
        </form>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', visibility: isUploading ? 'visible' : 'hidden' }}>
          <progress id="upload-progress" max="100" value="0"></progress>
        </div>
      </div>
    </div>
  );
}

export default App;
